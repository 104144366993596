import conf from './conf'

const baseURL = conf.value('VUE_APP_REPORTS_URL')

// FIXME clean this methods
const _isObj = function(a) {
  return !!a && a.constructor === Object
}

// FIXME clean this methods
const _st = function(z, g) {
  return '' + (g !== '' ? '[' : '') + z + (g !== '' ? ']' : '')
}

// FIXME clean this methods
const _fromObject = function(params, skipObjects, prefix) {
  if (skipObjects === void 0) {
    skipObjects = false
  }

  if (prefix === void 0) {
    prefix = ''
  }

  let result = ''
  if (typeof (params) != 'object') {
    return prefix + '=' + encodeURIComponent(params) + '&'
  }

  for (const param in params) {
    const c = '' + prefix + _st(param, prefix)
    if (_isObj(params[param]) && !skipObjects) {
      result += _fromObject(params[param], false, '' + c)
    } else if (Array.isArray(params[param]) && !skipObjects) {
      params[param].forEach(function(item) {
        result += _fromObject(item, false, c)
      })
    } else {
      result += c + '=' + encodeURIComponent(params[param]) + '&'
    }
  }

  return result
}

export function generateReport(params) {
  window.open(baseURL + 'reports?' + _fromObject(params))
}

// FIXME @Deprecated
const _print = function(title, mode, diskPath = null, dateFrom = null, dateTo = null, employeeId = null, status = null, pileId = null, plantId = null, pileType = null, ddtId = null, sector = null, dateFromStr = null, projectId = null, partId = null) {
  window.open(baseURL + 'reports'
    + '?title=' + title
    + '&mode=' + mode
    + (diskPath ? '&diskPath=' + diskPath : '')
    + (dateFrom ? '&dateFrom=' + dateFrom : '')
    + (dateTo ? '&dateTo=' + dateTo : '')
    + (employeeId ? '&employeeId=' + employeeId : '')
    + (status ? '&status=' + status : '')
    + (pileId ? '&pileId=' + pileId : '')
    + (plantId ? '&plantId=' + plantId : '')
    + (pileType ? '&pileType=' + pileType : '')
    + (ddtId ? '&ddtId=' + ddtId : '')
    + (sector ? '&sector=' + sector : '')
    + (dateFromStr ? '&dateFromStr=' + dateFromStr : '')
    + (projectId ? '&projectId=' + projectId : '')
    + (partId ? '&partId=' + partId : '')
  )
}

const reportsApi = {
  activityRequests: function(params) {
    params.title = 'ActivityRequest'
    params.mode = 'xls'
    generateReport(params)
  },

  employeeDailyReport: function(employeeId, dateFrom, dateTo) {
    _print('EmployeeDailyReport', 'xls', null, dateFrom, dateTo, employeeId)
  },

  projectSummaryReport: function(projectId, dateFromStr, partId) {
    _print('projectSummaryReport', 'xls', null, null, null, null, null, null, null, null, null, null, dateFromStr, projectId, partId)
  },

  pileMovementsReport: function(pileId, plantId, pileType) {
    _print('PileMovement', 'xls', null, null, null, null, null, pileId, plantId, pileType)
  },

  ddt: function(ddtId) {
    _print('Ddt', 'pdf', null, null, null, null, null, null, null, null, ddtId)
  }
}

export default reportsApi
