<template>
  <v-autocomplete v-model="selectedId"
                  :items="itemsList" :item-text="itemText" :item-value="itemValue"
                  @change="changeHandler"
                  :placeholder="translations[label]" :loading="loading"
                  :search-input.sync="inputToSearch"
                  :clearable="clearable" autocomplete="off"
                  dense hide-details outlined height="13px"
                  class="smart-autocomplete" style="width: 300px; font-size:12px">
    <!-- selection slot (selected item) -->
    <template v-slot:selection>
      <div class="d-flex">
        <span>
          {{ selectedItemTextComputed }}
        </span>
      </div>
    </template>

    <!-- custom items to be selected -->
    <template v-slot:item="data">
      <div class="d-flex">
        <span>
          {{ customItemTextFunction ? customItemTextFunction(data.item) : data.item[itemText] }}
        </span>
      </div>
    </template>

    <template v-slot:append-item>
      <div style="background: white;" class="d-flex justify-center" v-intersect="onIntersect" v-if="hasNextItems && !loading">
        <v-progress-circular indeterminate color="main"></v-progress-circular>
      </div>
    </template>

    <template v-slot:no-data>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="main" size="20"></v-progress-circular>
      </div>
      <v-list-item v-else>
        {{ translations.noEmployeesFoundWThatName }}
      </v-list-item>
    </template>

    <template v-if="showDeleteSlot" v-slot:append-outer>
      <v-btn @click="removeSelection" icon>
        <v-icon color="red">mdi-trash-can-outline</v-icon>
      </v-btn>
    </template>
  </v-autocomplete>
</template>


<script>
import { translations } from '@/utils/common'
import debounce from 'lodash/debounce'

export default {
  name: 'SmartAutocomplete',

  props: {
    //current value of the item
    value: {
      type: Object,
      default: () => ({})
    },

    //label for placeholder
    label: {
      type: String,
      required: true
    },

    //label for displaying when no results found
    noItemsLabel: {
      type: String,
      default: 'noResultsFound'
    },

    searchFunction: {
      type: Function,
      required: true
    },

    pageSize: {
      type: Number,
      default: 10
    },

    //this is used to pass as parameters to filter in the query
    propertyToFilterBy: {
      type: String,
      required: true
    },

    //the text to show in autocomplete list when selecting
    //should be a property of the object in the list
    itemText: {
      type: String,
      required: true
    },

    //if autocomplete requires a custom text function for items list
    customItemTextFunction: {
      type: Function,
      required: false
    },

    //the value in autocomplete list when selecting
    //should be a property of the object in the list
    itemValue: {
      type: String,
      required: true
    },

    // when preventing deletion of selection is required need to pass prop value false
    clearable: {
      type: Boolean,
      default: true
    },

    showDeleteSlot: {
      type: Boolean,
      default: false
    }
  },

  created() {
    if (this.value) this.setValueOnCreation()
    this.fetchItems()
  },

  data() {
    return {
      translations: translations,
      //to trigger search in autocomplete
      inputToSearch: '',
      //to use as filter in query
      input: '',
      loading: false,
      hasNextItems: false,
      itemsList: [],
      page: 1,
      selectedItemText: null, // to display as text when selected
      selectedId: null,
      missingItemValue: false // to keep track if itemValue is not present when value it at creation
    }
  },

  computed: {
    selectedItemTextComputed() {
      return this.selectedItemText
    }
  },

  watch: {
    inputToSearch: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.input = newVal
          this.loading = true
          this.itemFilterHandler()
        }
      },
      immediate: true
    }
  },

  methods: {
    getParams() {
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        [this.propertyToFilterBy]: this.input
      }

      return params
    },

    async fetchItems() {
      this.loading = true
      this.searchFunction({
          ...this.getParams()
        })
        .then(response => this.updateResults(response))
        .catch(() => this.updateResults())
    },

    async fetchMoreItems() {
      this.page++
      this.fetchItems()
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.fetchMoreItems()
      }
    },

    updateResults(data) {
      if (data === null || data === undefined) {
        this.page = 1
        this.itemsList = []
        this.hasNextItems = true
        return
      }

      this.hasNextItems = data?.hasNext
      this.itemsList = [...this.itemsList, ...data.content]

      // if we do not have the item value on creation we need to retrieve for value display
      if (this.missingItemValue) {
        this.selectedId = this.itemsList.find(item => item[this.itemText] === this.value[this.itemText])?.id
        this.missingItemValue = false
      }

      this.loading = false
    },

    changeHandler(val) {
      if (!val) {
        this.page = 1
        this.itemsList = []
        this.selectedItemText = ''
        this.input = ''
        this.inputToSearch = ''
        return this.fetchItems()
      }

      const item = this.itemsList.find(item => item.id === val)
      this.selectedItemText = item[this.itemText]

      this.$emit('set-value', item)
    },

    removeSelection() {
      this.changeHandler()
      this.$emit('remove-value')
    },

    itemFilterHandler: debounce(function() {
      this.page = 1
      this.itemsList = []
      this.selectedItemText = ''
      this.fetchItems()
    }, 850),

    setValueOnCreation() {
      this.selectedItemText = this.value[this.itemText]
      this.input = this.value[this.itemText]

      if (this.value[this.itemValue]) {
        this.selectedId = this.value[this.itemValue]
      } else {
        // if we do not have the item value on creation we need to retrieve for value display
        this.missingItemValue = true
      }
    }
  }
}
</script>


<style>
.smart-autocomplete.v-autocomplete.v-select.v-input--is-focused input {
  min-width: 0px !important;
}

.smart-autocomplete.v-input__control.v-input__slot.v-input__append-inner {
  margin: 0px !important;
  padding-left: 0px !important;
  min-width: 20px !important;
}

.smart-autocomplete.v-input__control.v-input__slot {
  max-height: 13px !important;
  box-sizing: unset !important;
}

.smart-autocomplete.v-input__control.v-input__slot.v-select__slot.v-select__selections input {
  padding: 0px !important;
  max-height: 13px !important;
}

.smart-autocomplete > .v-input__control > .v-input__slot > .v-select__slot > .v-input__append-inner > .v-input__icon {
  height: 20px !important;
  width: 18px !important;
  min-width: 18px !important;
  font-size: 20px !important;
}

.smart-autocomplete > .v-input__control > .v-input__slot > .v-select__slot > .v-input__append-inner > .v-input__icon > .v-icon {
  height: 18px !important;
  width: 18px !important;
  min-width: 18px !important;
  font-size: 18px !important;
}

.smart-autocomplete > .v-input__append-outer {
  margin: 0px !important;
}
</style>
